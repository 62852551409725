<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-header">
        <div class="select-title">
          <span>设备状态</span>
        </div>
        <div v-role="'RS-M04-F01'" class="select-items">
          <span @click="handleCreate">
            <img src="@/assets/icon/equipment.png" />
            新增设备
          </span>
        </div>
      </div>
      <div class="select-content">
        <el-form ref="form" :model="filter">
          <el-row>
            <el-col :span="7">
              <el-form-item label="组织名称">
                <organization-select :model="filter"></organization-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="税控设备">
                <el-input v-model="filter.alias" placeholder="请输入设备名称" @keyup.enter.native="reqRecords()" />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="开票方式">
                <el-select v-model="filter.billingChannel" placeholder="全部">
                  <el-option v-for="item in allBillingChannels" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3" style="text-align: right">
              <el-form-item>
                <el-button type="primary" @click="handleQuery" :disabled="recordsLoading">查询</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div class="content-table">
        <el-table :data="records" stripe current-row-key="id" row-key="id" border v-loading="recordsLoading" :header-cell-style="handleHeaderCellStyle" style="width: 100%">
          <el-table-column type="index" fixed="left" label="序号" width="60"></el-table-column>
          <el-table-column prop="orgName" fixed="left" show-overflow-tooltip align="left" label="组织名称" min-width="180"></el-table-column>
          <el-table-column prop="extensionNo" label="分机编号" width="100" fixed="left"></el-table-column>
          <el-table-column prop="billingChannel" label="开票方式" width="160">
            <template slot-scope="scope">
              <span>{{ fmtBillingChannel(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="defaulted" label="默认设备" width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.defaulted ? '是' : '否' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="orgTaxNo" label="组织税号" min-width="160"></el-table-column>
          <el-table-column prop="alias" label="设备名称" width="100"></el-table-column>
          <el-table-column prop="agentStatus" label="助手状态" width="80">
            <template slot-scope="scope">
              <agent-online-status :value="scope.row.agentStatus"></agent-online-status>
            </template>
          </el-table-column>
          <el-table-column prop="deviceStatus" label="税盘状态" width="80">
            <template slot-scope="scope">
              <device-online-status :value="scope.row.deviceStatus"></device-online-status>
            </template>
          </el-table-column>
          <el-table-column prop="matchStatus" label="匹配状态" width="100">
            <template slot-scope="scope">
              <device-match-status :value="scope.row.matchStatus"></device-match-status>
            </template>
          </el-table-column>
          <el-table-column prop="openStatus" label="开通状态" width="80">
            <template slot-scope="scope">
              <span>{{ fmtOpenStatus(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="equipmentType" label="设备类型" width="80">
            <template slot-scope="scope">
              <span>{{ fmtEquipmentType(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="120">
            <template slot-scope="scope">
              <el-button type="text" size="medium" @click="handleEdit(scope.row)">编辑 </el-button>
              <el-button type="text" size="medium" @click="handleDetail(scope.row)">详情 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="paging.current" :page-sizes="pageSizes" :page-size="paging.size" layout="total, sizes, prev, pager, next" :total="paging.total"> </el-pagination>
      </div>
    </div>
    <el-drawer append-to-body :visible.sync="detailVisible" size="45%" :with-header="false">
      <equipment-detail :record="record" :recordLoading="recordLoading" @cancel="handleCancelDetail" @update="handleUpdate"></equipment-detail>
    </el-drawer>
    <el-dialog append-to-body title="新增设备信息" :visible.sync="createVisible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" top="12vh" width="764px">
      <equipment-edit ref="createRecord" :record="createRecord" @cancel="handleCancelCreate" @saved="handleSaved"></equipment-edit>
    </el-dialog>
    <el-dialog append-to-body title="修改设备信息" :visible.sync="updateVisible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" top="12vh" width="764px">
      <equipment-edit :record="record" :recordLoading="recordLoading" @cancel="handleCancelUpdate" @saved="handleSaved"></equipment-edit>
    </el-dialog>
  </div>
</template>

<script>
import AgentOnlineStatus from '@/components/widgets/AgentOnlineStatus.vue';
import DeviceOnlineStatus from '@/components/widgets/DeviceOnlineStatus.vue';
import DeviceMatchStatus from '@/components/widgets/DeviceMatchStatus.vue';
import { ReqEquipmentGetDetail, ReqEquipmentGetListByPage } from '@/service/equipment';
import { tableStyle } from '@/util/mixins';
import EquipmentDetail from '@/views/equipment/Detail.vue';
import EquipmentEdit from '@/views/equipment/Edit';
import { DataUtil } from '@/util/dataUtil';
import OrganizationSelect from '@/components/widgets/OrganizationSelect';

const CREATE_RECORD = {
  id: 0,
  orgId: undefined,
  extensionNo: '',
  alias: '',
  openStatus: '0',
  password: '',
  billingChannel: '',
  disabled: false,
  identifier: '',
  contactPersons: '',
  contactChannel: '',
  location: '',
  agentUsername: '',
  agentPassword: '',
  autoPull: false,
  defaulted: false,
  addressTel: '', //电话地址 （页面展示）
  bankAccount: '', //银行账户 （页面展示）
  payee: '',
  auditor: '',
  payer: '',
  elecType: 0,
  electronicOrdinaryInvoiceLimit: '10000.00', //电子普票限额
  paperSpecialInvoiceLimit: '10000.00', //纸质专票限额
  paperOrdinaryInvoiceLimit: '10000.00' //纸质普票限额
};

export default {
  name: 'SysEqpt',
  mixins: [tableStyle],
  components: {
    OrganizationSelect,
    EquipmentDetail,
    EquipmentEdit,
    AgentOnlineStatus,
    DeviceOnlineStatus,
    DeviceMatchStatus
  },
  data() {
    return {
      filter: {
        orgId: 0,
        alias: undefined,
        billingChannel: undefined
      },
      paging: {
        current: 1,
        size: 15,
        total: 0
      },
      pageSizes: [15, 30, 50, 100],
      organizations: [],
      billingChannels: [],
      allBillingChannels: [],
      equipmentTypes: [],
      allEquipmentTypes: [],
      agentOnlineStatus: [],
      deviceOnlineStatus: [],
      deviceMatchStatus: [],
      records: [],
      recordsLoading: false,
      // 详情
      record: {},
      recordId: 0,
      recordLoading: false,
      detailVisible: false,
      // 新增
      createVisible: false,
      openStatus: {},
      createRecord: DataUtil.clone(CREATE_RECORD),
      // 修改
      updateVisible: false
    };
  },
  created() {
    let o = this.$store.state.user.organizations;
    this.organizations = [{ id: 0, name: '全部', taxNo: '', auditStatus: 'Initial' }, ...o];
    let enums = this.$store.state.enums;
    this.agentOnlineStatus = enums.AgentOnlineStatus;
    this.deviceOnlineStatus = enums.DeviceOnlineStatus;
    this.deviceMatchStatus = enums.DeviceMatchStatus;

    Promise.all([this.getDicts('equ_open_status'), this.getDicts('equ_billing_mode'), this.getDicts('equipment_type')])
      .then((results) => {
        let osRes = results[0];
        if (osRes.success) {
          this.openStatus = osRes.data;
        }

        let bmRes = results[1];
        if (bmRes.success) {
          this.allBillingChannels = [{ value: '', label: '全部' }, ...bmRes.data];
          this.billingChannels = bmRes.data;
        }

        let bmType = results[2];
        if (bmType.success) {
          this.allEquipmentTypes = [{ value: '', label: '全部' }, ...bmType.data];
          this.equipmentTypes = bmType.data;
        }

        this.handleQuery();
      })
      .catch((err) => {});
  },
  methods: {
    //查询设备
    handleQuery() {
      this.paging.current = 1;
      this.reqRecords();
    },
    handleSizeChange(val) {
      this.paging.size = val;
    },
    //新增
    handleCreate() {
      this.createVisible = true;
    },
    handleCancelCreate() {
      this.createVisible = false;
    },
    //详细
    handleDetail(row) {
      this.recordId = row.id;
      this.getDetail(this.recordId);
      this.detailVisible = true;
    },
    //编辑
    handleEdit(row) {
      this.getDetail(row.id);
      this.updateVisible = true;
    },
    handleCancelDetail() {
      this.detailVisible = false;
    },
    //修改
    handleUpdate(id) {
      this.getDetail(id);
      this.updateVisible = true;
    },
    handleCancelUpdate() {
      this.updateVisible = false;
    },
    //保存
    handleSaved(data, mode) {
      switch (mode) {
        case 'create':
          this.toast('新增设备信息成功', 'success');
          const len = this.records.length;
          this.createVisible = false;
          this.createRecord = DataUtil.clone(CREATE_RECORD);
          this.$refs.createRecord.resetRecordForm();
          if (len >= this.paging.size) {
            return;
          }
          let nr = DataUtil.clone(data);
          let org = this.organizations.find((o) => o.id == data.orgId);
          if (org) Object.assign(nr, { orgName: org.name, orgTaxNo: org.taxNo });
          this.records.push(nr);
          break;
        case 'update':
          this.toast('修改设备信息成功', 'success', () => {
            this.updateVisible = false;
            this.reqRecords();
          });
          break;
        default:
          break;
      }
    },
    handleCurrentChange(val) {
      this.paging.current = val;
      this.reqRecords();
    },
    reqRecords() {
      this.recordsLoading = true;
      this.filter.page = this.paging.current;
      this.filter.size = this.paging.size;
      ReqEquipmentGetListByPage(this.filter).then((res) => {
        if (res.success) {
          this.records = res.data.records;
          this.paging.total = res.data.total;
        }
        this.recordsLoading = false;
      });
    },
    getDetail(id) {
      this.recordLoading = true;
      ReqEquipmentGetDetail({ id: id })
        .then((res) => {
          if (res.success) {
            this.record = res.data;
          }
          this.recordLoading = false;
        })
        .catch(() => {
          this.recordLoading = false;
        });
    },
    // 格式化
    fmtBillingChannel(row) {
      return this.getDictLabel(this.billingChannels, row.billingChannel);
    },
    fmtOpenStatus(row) {
      return this.getDictLabel(this.openStatus, row.openStatus);
    },
    fmtEquipmentType(row) {
      console.log(row.equipmentType);
      if (row.equipmentType == null || row.equipmentType == '') {
        return '开票设备';
      }
      return this.getDictLabel(this.equipmentTypes, row.equipmentType);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 24px;
  text-align: initial;
}

.select-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-items {
    display: inline-flex;

    span {
      color: #3d94ff;
      margin-right: 20px;
      font-weight: 500;
      font-size: 16px;

      img {
        width: 18px;
        height: 18px;
        vertical-align: sub;
        margin-right: 4px;
      }
    }

    span:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .select-title span:before {
    content: '|';
    display: inline-block;
    margin-right: 10px;
    background-color: #333;
  }
}

.invoice-dialog {
  div {
    margin-bottom: 12px;
  }

  span {
    width: 240px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 20px;
  }

  .el-image {
    width: 200px;
    height: 200px;
  }
}

.content-main {
  min-height: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .el-button {
    padding: 0;
  }
}
</style>
